/* body {
  background-color: #add8e6; // Baby blue background 
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
} */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to top, #001f3f, #0074d9);
  min-height: 100vh; /* Ensures the gradient covers the full height of the viewport */
  padding: 10px 20px;
  border-radius: 20px;
  color: rgba(0, 208, 255, 0.814);
  font-size: 48px;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.bubble-card {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: rgba(
    0,
    116,
    217,
    0.549
  ); /* Adjust color and alpha value as needed */
  color: rgba(0, 208, 255, 0.814);
  font-size: 4px;
  font-weight: bold;

  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Optional: Add text shadow for depth */
}
.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

/* Ensure bubble styles are included */
.bubble {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #53545446, #ffffff4e);
  border-radius: 50%;
  animation: floatUp 1s infinite;
  opacity: 0;
}
.bubble2 {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #53545463, #ffffff);
  border-radius: 50%;
  animation: floatUp 10s infinite;
  opacity: 0;
}
.email-link {
  color: rgba(0, 208, 255, 0.814);
}
@keyframes floatUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-5000px);
    opacity: 0;
  }
}
